@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.team-member-content {
  max-height: 150px;
  /* Adjust height to suit your design */
  overflow: hidden;
  position: relative;
}

.team-member-content.expanded {
  max-height: none;
}

.team-image-wrapper {
  width: 100%;
  height: 300px;
  /* Fixed height for consistency */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
}

.team-image {
  width: auto;
  height: 100%;
  object-fit: cover;

  /* Ensures the image covers the div without distortion */
}

.team-member-content {
  max-height: 150px;
  /* Adjust height to suit your design */
  overflow: hidden;
  position: relative;
}

.team-member-content.expanded {
  max-height: none;
}