#programs {
  display: flex;
  flex-direction: column;
  /* background: #1d1f32; */
}

@media (650px <= width <= 1170px) {
  #programs {
    padding: 0 20%;
  }
}

@media (650px <= width <= 800px) {
  #programs {
    padding: 0 20%;
  }
}

@media (width < 650px) {
  #programs {
    padding: 0 15%;
  }
}

@media (width < 550px) {
  #programs {
    padding: 0 10%;
  }
}

/* .css-1sj2t5c {
  width: 110%;
} */

.program-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
