.student-chats-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.student-chats-container .selection-container,
.student-chats-container .specific-selection-container {
  text-align: center;
  padding: 20px;
}

.student-chats-container .selection-heading {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.student-chats-container .button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.student-chats-container .selection-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.student-chats-container .selection-button:hover {
  background-color: #0056b3;
}

.student-chats-container .chat-container {
  padding: 20px;
}

.student-chats-container .chat-heading {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
  text-align: center;
}

.student-chats-container .chat-window {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  height: 300px;
  overflow-y: auto;
  background-color: #fff;
}

.student-chats-container .chat-message {
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #e9ecef;
}

.student-chats-container .chat-input-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.student-chats-container .chat-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.student-chats-container .send-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.student-chats-container .send-button:hover {
  background-color: #218838;
}