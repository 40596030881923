.sidebar {
  min-width: 60px;
  transition: width 0.3s;
}

.sidebar.open {
  min-width: 160px;
}

.content {
  transition: margin-left 0.3s;
}

.content .toolbar-spacer {
  max-width: 60px;
  height: 56px;
  /* Adjust this if your Navbar height is different */
}

.sidebar.open + .content {
  margin: auto;
}

/* YourCardStyles.css */

.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.custom-card {
  background-color: #fff;
  /* Adjust background color */
  padding: 20px;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  /* Ensure content stays within borders */
}

.custom-border {
  border: 2px solid #ccc;
  /* Adjust border styles as needed */
  border-radius: 8px;
  /* Optional: Add border radius */
}

.card-image {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  /* Optional: Rounded corners for images */
}

.title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.description {
  font-size: 1rem;
  /* color: #666; */
}

.team-image {
  width: 100%;
  max-width: 400px;
  /* Maximum width for the images */
  height: auto;
  /* Maintain aspect ratio */
  object-fit: cover;
  /* Ensure the image covers the specified area */
}

/* { What Our Products Measure Section } */

/* Fade-in and slide-up animation */
@keyframes fade-in-slide-up {
  from {
    opacity: 0;
    transform: translateY(50px); /* Start slightly lower */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* End at the original position */
  }
}

/* Fade-in animation */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Bounce animation */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

/* Apply bounce animation to icons */
.landing-icon-bounce {
  display: inline-block;
  animation: bounce 2s infinite; /* Bounce animation repeats every 2 seconds */
}

/* Hover effect for cards */
.landing-card-hover {
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.landing-card-hover:hover {
  transform: scale(1.05); /* Scale up slightly on hover */
}
