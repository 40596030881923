.performance-student-container {
  padding: 20px;
}

.h1-student {
  text-align: center;
  margin-bottom: 40px;
}

.test-report-div {
  margin-bottom: 40px;
}

.course-completion-div {
  margin-bottom: 40px;
}

.progress-bar-container {
  background-color: #f1f1f1;
  border-radius: 20px;
  height: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.progress-bar-inner {
  background-color: #4caf50;
  height: 100%;
  border-radius: 20px;
}

.lesson-progress-bar {
  margin-bottom: 20px;
}
