.demovdos {
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #7335b7;
}

.demovdos-stories {
  padding: 1rem 0;
  background-color: #0741ff;
}

.demovdos-images {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  position: relative;
}

.demovdos-images_container {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.demovdos-images_container::-webkit-scrollbar {
  display: none;
}

.demovdos-images_card {
  position: relative;
  min-width: fit-content;
  margin-right: 1rem;
  margin-left: 1rem;
  position: sticky;
  /* left: 5vw; */
  /* display: flex; */
}

.demovdos-images_card .demovdos-image {
  max-width: 100%;
  /* width: max-content; */
  height: auto;
  /* height: 70vh; */
  width: 100vh;
}

.demovdos-images_arrows {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  position: absolute;
  bottom: 50%;
}

.demovdos-images_arrows-story {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
  position: absolute;
  bottom: 50%;
}

.demovdos-arrow-icon {
  /* color: rgb(0, 255, 174); */
  color: #ffffff;
  font-size: 2.5rem;
  cursor: pointer;
  background: black;
  border-radius: 10px;
}

.demovdos-arrow-icon-story {
  /* color: rgb(0, 255, 174); */
  color: #ffffff;
  font-size: 4.5rem;
  cursor: pointer;
  background: black;
  border-radius: 10px;
}

.demovdos-arrows-div {
  /* background: rgb(0, 255, 174); */
  background: #ffffff;
  padding: 3px;
  border-radius: 10px;
}

.demovdos-arrows-div-story {
  /* background: rgb(0, 255, 174); */
  background: #ffffff;
  padding: 3px;
  border-radius: 10px;
}

.demovdos-arrow-icon:hover {
  color: rgb(57, 255, 97);
}

.demovdos-arrow-icon-story:hover {
  color: rgb(57, 255, 97);
}

.demovdos-arrows-div:hover {
  background: rgb(57, 255, 97);
}

.demovdos-arrows-div-story:hover {
  background: rgb(57, 255, 97);
}

.demovdos-images_card:nth-child(1) img {
  transform: scale(0.75) rotate(-10deg);
}

.demovdos-images_card:nth-child(2) img {
  transform: scale(0.75) rotate(-7deg);
}

.demovdos-images_card:nth-child(3) img {
  transform: scale(0.75) rotate(-4deg);
}

.demovdos-images_card:nth-child(4) img {
  transform: scale(0.75) rotate(-1deg);
}

.demovdos-images_card:nth-child(5) img {
  transform: scale(0.75) rotate(2deg);
}

.demovdos-images_card:nth-child(6) img {
  transform: scale(0.75) rotate(5deg);
}

.demovdos-images_card:nth-child(7) img {
  transform: scale(0.75) rotate(8deg);
}

.demovdos-images_card:nth-child(8) img {
  transform: scale(0.75) rotate(11deg);
}

@media (width<= 750px) {
  .demovdos-images_card .demovdos-image {
    width: max-content;
    height: auto;
  }
}
