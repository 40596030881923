.performanceGraphsDiv {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.h1Graphs {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 30px;
}

.selectDivPerformance {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.selectDivPerformance label {
  font-weight: 600;
  color: #555;
}

.selectDivPerformance select {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.selectDivPerformance select:focus {
  border-color: #3498db;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.3);
}

.selectDivPerformance select[multiple] {
  height: 150px;
  overflow-y: auto;
}

.studentComparisonDiv,
.classComparisonDiv {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.studentComparisonDiv h3 {
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 15px;
}

.classComparisonDiv h3 {
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 15px;
}


.classComparisonDiv h4 {
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 15px;
}

.classComparisonDiv h5 {
  font-weight: 500;
  color: #7f8c8d;
  margin-top: 10px;
}

.student-selector {
  color: #777;
  font-size: 1rem;
  text-align: center;
  margin-top: 20px;
}

.classComparisonDiv div {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 500;
  color: #2c3e50;
}

.selectDivPerformance {
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 500;
  color: #2c3e50;
}

@media (max-width: 768px) {
  .h1Graphs {
    font-size: 2rem;
  }

  .selectDivPerformance {
    flex-direction: column;
    align-items: stretch;
  }

  .selectDivPerformance select {
    width: 100%;
  }

  .studentComparisonDiv,
  .classComparisonDiv {
    padding: 15px;
  }
}