.student-curriculum {
  font-family: 'Arial', sans-serif;
  max-width: 900px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.student-curriculum .title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.level-selector {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.level-selector label {
  margin-bottom: 8px;
  font-weight: bold;
}

.level-selector select {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 16px;
}

.student-curriculum .tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.student-curriculum .tabs .tab-button {
  padding: 10px 20px;
  border: none;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.student-curriculum .tabs .tab-button.active {
  background-color: #007bff;
  color: #fff;
}

.student-curriculum .tabs .tab-button:hover {
  background-color: #d1d1d1;
}

.student-curriculum .content {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.student-curriculum .content .content-title {
  color: #007bff;
  margin-bottom: 10px;
}