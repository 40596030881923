.engagement-reports h1 {
  text-align: center;
  margin-top: 20px;
  color: #2c3e50;
}

.engagement-reports label {
  display: block;
  margin: 20px 0 10px;
  font-weight: bold;
  color: #34495e;
}

.engagement-reports label select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #ecf0f1;
}

.engagement-reports label select option {
  padding: 10px;
}

.render-student-activity h3 {
  font-size: 1.2em;
  color: #2980b9;
  margin-bottom: 10px;
}

.render-student-activity p {
  margin: 5px 0;
}

.render-student-activity ul {
  list-style-type: none;
  padding: 0;
}

.render-student-activity ul li {
  background-color: #e3f2fd;
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
}

.render-student-activity h4 {
  margin-top: 15px;
  color: #16a085;
}

.no-selection {
  color: #e74c3c;
  text-align: center;
  margin-top: 20px;
  font-size: 1.1em;
  margin: "5px 0";
}