/* Fade-in effect for container */
.lesson-fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Hover effect for cards */
.lesson-card-hover{
  margin: 1rem;
}
.lesson-card-hover:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Zoom-in effect for images on hover */
.lesson-image {
  width: 100%;
  /* height: 200px; */
  object-fit: cover;
  transition: transform 0.5s ease;
}

.lesson-image:hover {
  transform: scale(1.1);
}

/* Additional styling for Card Col */
.lesson-card-col {
  margin-bottom: 30px;
}

.lesson-card-hover {
  height: 350px;
}

.lesson-image {
  width: 100%;
  max-height: 200px;
}

.lesson-image-dashboard {
  width: 100%;
  max-height: 850px;
}

@media (max-width: 768px) {
  .lesson-card-hover {
    height: 300px;
  }
  .lesson-image {
    max-height: 120px;
  }
}

@media (max-width: 576px) {
  .lesson-card-hover {
    height: 250px;
  }
  .lesson-image {
    max-height: 100px;
  }
  .lesson-card-hover .card-title {
    font-size: 1rem;
  }
}
