.smaller-card {
  max-width: 250px;
  margin-bottom: 20px;
}

.smaller-card-img {
  max-height: 150px;
  object-fit: cover;
}

.highlighted-border {
  border: 2px solid transparent;
  border: 2px solid transparent;
  transition: border-color 0.4s ease-in-out;
}

.highlighted-border:hover {
  border: 5px solid #19ffc9d7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
}

.activeLesson {
  border: 5px solid #19ffc9d7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
  transform: scale(1.05);
  transition: transform 0.3s, box-shadow 0.3s;
}

@media (max-width: 768px) {
  .smaller-card {
    max-width: 100%;
  }
}
